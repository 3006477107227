html.dark {
    color-scheme: dark;

    body {
        background-color: #1c1c1c;
        color: #dadada;
    }
}

html.light {
    body {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
    }
}

body {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}
